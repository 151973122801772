<template>
  <div class="WorldRanking">
    <div class="top">
        <div class="__title">
        <span>联邦快递杯积分榜</span>
      </div>
    </div>
    <!--    表格数据-->
    <div class="__list">
      <div class="__top" v-if="false">
        <span class="iconfont icon-sanjiaoyou"></span>
        <span>美巡平均：</span>
        <span>$10000</span>
      </div>
      <div class="_list">
        <div class="list_child">
          <div class="line01">本周排名</div>
          <div class="line01">上周排名</div>
          <div class="line02">球员名称</div>
          <div class="line03">参赛次数</div>
          <div class="line04">积分</div>
          <div class="line04">获胜次数</div>
          <div class="line04">前十次数</div>
          <!-- <div class="line04">领先优势</div> -->
          <div class="line04">离榜首差距</div>
          <div class="line04">重置点</div>
        </div>
        <div class="list_child list_tr_child"
             :class="{'bg':index%2===0}"
             v-for="(item, index) in list" :key="index">
          <div class="line01">{{item.curRank}}</div>
          <div class="line01">{{item.prevRank}}</div>
          <div class="line02 line02_other">
            <span>{{item.player_name}}</span>
            <br/><span class="plaer_nameEn">{{item.e_name}}</span>
          </div>
          <div class="line03">{{item.statValues.rndEvents}}</div>
          <div class="line04">{{item.statValues.statValue1}}</div>
          <div class="line04">{{item.statValues.statValue2}}</div>
          <div class="line04">{{item.statValues.statValue3}}</div>
          <div class="line04">{{item.statValues.statValue4}}</div>
          <div class="line04">{{item.statValues.statValue5}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";

  export default {
    name: "WorldRanking",
    data() {
      return {
        isShow_match:false,
        list:[]
      }
    },
    created() {
      this.getList()
    },
    methods: {

      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:'02671'
          }
        })
        if(data!==null){
          this.list = data.sta
        } 
      
      }
    }
  }
</script>

<style scoped lang="less">
  .WorldRanking{
    width: 1225px;
    padding-top: 40px;
    .top{
      padding: 10px 10px 10px 30px;
      .__title{
        font-size: 30px;
        span{
          //font-family: My_Bold;
          font-weight: 500;
        }
      
      }
    }

    .__list{
      padding-top: 20px;
      padding-bottom: 20px;
      .__top{
        padding-left: 30px;
        font-size: 16px;
        padding-bottom: 20px;
        span{
          //font-family: My_Medium;
        }
        span:nth-child(2){
          //font-family: My_Medium;
          font-weight: 600;
        }
      }

      ._list{
        font-size: 16px;
        .list_child{
          display: flex;
          text-align: center;
          line-height: 50px;
          border: 1px solid #eeeeee;
          .line01{
            //font-family: My_Medium;
            width: 120px;
            border-right: 1px solid #eeeeee;
          }
          .line02{
            //font-family: My_Medium;;
            width: 400px;
            padding-left: 30px;
            text-align: left;
            border-right: 1px solid #eeeeee;
          }
          .line02_other{
            //font-family: My_Medium;
            color: #003e7e;
          }
          .line03{
            //font-family: My_Medium;
            width: 160px;
            border-right: 1px solid #eeeeee;
          }
          .line04{
            //font-family: My_Medium;
            width: 235px;
            border-right: 1px solid #eeeeee;
          }
          .line05{
            //font-family: My_Medium;
            width: 109px;
            /*border-right: 1px solid #000000;*/
          }
          .plaer_nameEn{
            color: #333333;
            font-weight: 600;
          }
        }
        .list_child:nth-child(1){
          background-image: linear-gradient(#ffffff 25px, #ebebeb);
          div{
            //font-family: My_Medium;
          }
        }
        .bg{
          background-color: #f2f2f2;
        }
      }

      ._list{
        .list_tr_child{
          line-height: 35px !important;
          padding-top: 5px;
        }

        .bg{
          background-color: #f2f2f2;
        }
      }
    }
  }
</style>